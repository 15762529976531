import React from "react";
import SupportPrompt from "../SupportPrompt";

function PlanError() {
  return (
    <>
      <p>We had trouble getting your plan information.</p>
      <p>
        If this keeps happening,{" "}
        <SupportPrompt>we're happy to chat.</SupportPrompt>
      </p>
    </>
  );
}

export default PlanError;
