import React from "react";
import "../../styles/ProfileSegment.css";

interface Props {
  children: React.ReactNode;
}

function ProfileSegment(props: Props) {
  return <div className="ProfileSegment">{props.children}</div>;
}

export default ProfileSegment;
