import React, { useState } from "react";
import ReactGA from "react-ga";
import Button from "../Button";
import { SubscriptionData } from "../../models";
import cancelSubscription from "../../api/cancelSubscription";
import { queryCache, useMutation } from "react-query";
import generateSnackbar from "../../helpers/generateSnackbar";
import sendEmail from "../../api/sendEmail";
import Modal from "../base/Modal";

interface Props {
  nextChargeDate: string;
  userId: string;
  accessToken: string;
}

interface TVariables {
  userId: string;
  accessToken: string;
}

function PremiumPlan(props: Props) {
  const { nextChargeDate, userId, accessToken } = props;
  const [isModalOpen, setModalOpenState] = useState(false);

  const [cancelSubscriptionMutation, { status: cancelStatus }] = useMutation<
    SubscriptionData,
    TVariables
  >(cancelSubscription, {
    onSuccess: (data, { userId, accessToken }) => {
      queryCache.setQueryData(["subscription", userId, accessToken], data);

      sendEmail({
        name: userId,
        email: "andrico@calisthenicsskills.com",
        subject: "cancelling subscription",
        message: "cancelling subscription"
      });

      ReactGA.event({
        category: "Plans",
        action: `Cancelled Plan`,
        label: `Successfully Cancelled Plan`,
        nonInteraction: true
      });

      generateSnackbar({
        type: "info",
        message: "You've canceled your subscription. We're going to miss you."
      });
    },
    onError: () => {
      ReactGA.event({
        category: "Plans",
        action: `Error Cancelling Plan`,
        label: `Error Cancelling Plan`,
        nonInteraction: true
      });

      generateSnackbar({
        type: "error",
        message:
          "We had a problem cancelling your subscription. You can get in touch if this keeps happening",
        actionMessage: "Let us know",
        handleAction: () => {
          window.$crisp.push(["do", "chat:open"]);
        }
      });
    }
  });

  return (
    <>
      <p>Plan Type: Premium</p>
      <p>
        Your subscription will renew on {nextChargeDate}. You can cancel your
        subscription anytime before it renews.
      </p>
      <Button
        disabled={cancelStatus === "loading"}
        handleClick={() => setModalOpenState(true)}
      >
        Cancel Subscription
      </Button>
      <Modal
        isOpen={isModalOpen}
        heading="Cancel Subscription"
        message="Are you sure you want to cancel your subscription?"
        primaryAction={{
          handleAction: () => {
            cancelSubscriptionMutation({
              accessToken,
              userId
            });
          },
          text: "Yes"
        }}
        secondaryAction={{
          handleAction: () => setModalOpenState(false),
          text: "Cancel"
        }}
      />
    </>
  );
}

export default PremiumPlan;
