import React from "react";
import ReactGA from "react-ga";
import Button from "../Button";
import { useMutation, queryCache } from "react-query";
import uncancelSubscription from "../../api/uncancelSubscription";
import { SubscriptionData } from "../../models";
import generateSnackbar from "../../helpers/generateSnackbar";
import sendEmail from "../../api/sendEmail";

interface Props {
  deactivationDate: string;
  accessToken: string;
  userId: string;
}

interface TVariables {
  userId: string;
  accessToken: string;
}

function CanceledPlan(props: Props) {
  const { deactivationDate, accessToken, userId } = props;

  const [
    uncancelSubscriptionMutation,
    { status: uncancelStatus }
  ] = useMutation<SubscriptionData, TVariables>(uncancelSubscription, {
    onSuccess: (data, { userId, accessToken }) => {
      queryCache.setQueryData(["subscription", userId, accessToken], data);

      sendEmail({
        name: userId,
        email: "andrico@calisthenicsskills.com",
        subject: "cancelling subscription",
        message: "cancelling subscription"
      });

      ReactGA.event({
        category: "Plans",
        action: `Uncanceled Plan`,
        label: `Successfully Uncancelled Plan`
      });

      generateSnackbar({
        type: "info",
        message:
          "Your subscription has been uncanceled. We're happy to see you stay. "
      });
    },
    onError: () => {
      ReactGA.event({
        category: "Plans",
        action: `Error Uncancelling Plan`,
        label: `Error Uncancelling Plan`,
        nonInteraction: true
      });

      generateSnackbar({
        type: "error",
        message:
          "We had a problem uncancelling your subscription. You can get in touch if this keeps happening",
        actionMessage: "Let us know",
        handleAction: () => {
          window.$crisp.push(["do", "chat:open"]);
        }
      });
    }
  });

  return (
    <>
      <p>Plan Type: Premium</p>
      <p>
        You've canceled your Cali Skills subscription. You will lose
        access to premium features on {deactivationDate}. It's not too late to
        uncancel.
      </p>
      <Button
        disabled={uncancelStatus === "loading"}
        handleClick={() =>
          uncancelSubscriptionMutation({
            accessToken,
            userId
          })
        }
      >
        Uncancel Subscription
      </Button>
    </>
  );
}

export default CanceledPlan;
