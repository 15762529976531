import useAuth from "../hooks/useAuth";
import { RouteComponentProps } from "@reach/router";

interface Props extends RouteComponentProps {
  render: (props: RouteComponentProps) => JSX.Element;
}

function PrivateRoute({ path = "/", render, ...props }: Props) {
  const { getAuthenticationState, login, tokenRenewalComplete } = useAuth();
  const isAuthenticated = getAuthenticationState();

  if (!tokenRenewalComplete) {
    return null;
  }

  if (!isAuthenticated) {
    login(path);
    return null;
  }

  return render({ ...props });
}

export default PrivateRoute;
