import React from "react";
import PrivateRoute from "../components/PrivateRoute";
import BasicInformationForm from "../components/BasicInformationForm";
import TextPageLayout from "../components/layouts/TextPageLayout";
import Dashboard from "../components/Dashboard";
import AccountInformation from "../components/AccountInformation";
import AccountActions from "../components/AccountActions";

function Profile() {
  return <PrivateRoute path="/profile/" render={() => <ProfileContent />} />;
}

function ProfileContent() {
  return (
    <TextPageLayout title="Your Profile">
      <BasicInformationForm />
      <AccountInformation />
      <AccountActions />
      <Dashboard />
    </TextPageLayout>
  );
}

export default Profile;
