interface EmailInput {
  name: string;
  email: string;
  message: string;
  subject: string;
}

function sendEmail(input: EmailInput) {
  return fetch(process.env.GATSBY_EMAIL_ENDPOINT, {
    method: "POST",
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(input)
  });
}

export default sendEmail;
