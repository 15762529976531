function resetUserSkills(userId: string, accessToken: string) {
  return fetch(`${process.env.GATSBY_SERVER_URL}/user-skills/${userId}/reset`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    }
  });
}

export default resetUserSkills;
