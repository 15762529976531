import React, { useState } from "react";
import Button from "./Button";
import useAuth from "../hooks/useAuth";
import resetUserSkills from "../api/resetUserSkills";
import generateSnackbar from "../helpers/generateSnackbar";
import Modal from "./base/Modal";

interface ResetSkillsArgs {
  userId: string;
  accessToken: string;
}

async function resetSkills(args: ResetSkillsArgs) {
  const { userId, accessToken } = args;
  try {
    await resetUserSkills(userId, accessToken);

    generateSnackbar({
      type: "success",
      message: "Your skill progress has successfully been reset"
    });
  } catch (error) {
    console.error(error);

    generateSnackbar({
      type: "error",
      message:
        "There was an error while reseting your skills. Please try again later"
    });
  }
}

// test that this still works
function ResetSkillsButton() {
  const { getUserId, getAccessToken } = useAuth();
  const [isModalOpen, setModalOpenState] = useState(false);

  return (
    <>
      <p>Danger: Resetting your skills trees is a permanent action.</p>
      <Button handleClick={() => setModalOpenState(true)}>Reset Skills</Button>

      <Modal
        isOpen={isModalOpen}
        heading="Reset Skills"
        message="Are you sure you want to reset your skill trees? All of your
              completed exercises will be marked as not complete. This action
              cannot be undone."
        primaryAction={{
          handleAction: () => {
            const args = {
              userId: getUserId(),
              accessToken: getAccessToken()
            };
            setModalOpenState(false);
            resetSkills(args);
          },
          text: "Reset Skills"
        }}
        secondaryAction={{
          handleAction: () => setModalOpenState(false),
          text: "Cancel"
        }}
      />
    </>
  );
}

export default ResetSkillsButton;
