import React from "react";
import ProgressChart from "./charts/ProgressChart/ProgressChart";
import ProgressionsRadarChart from "./charts/ProgressionsRadarChart/ProgressionsRadarChart";
import ProfileSegment from "./layouts/ProfileSegment";
import useIsSubscriptionActive from "../hooks/useIsSubscriptionActive";

function Dashboard() {
  const isSubscriptionActive = useIsSubscriptionActive();

  return (
    <ProfileSegment>
      <h2 style={{ textAlign: "left" }}>Your Progress Dashboard</h2>
      <ProgressChart isPremium={Boolean(isSubscriptionActive)} />
      <ProgressionsRadarChart isPremium={Boolean(isSubscriptionActive)} />
      <p>
        Want to be featured in our Instagram stories? Just share your progress
        on Instagram and tag{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://instagram.com/caliskills.app"
        >
          Cali Skills
        </a>
        .
      </p>
    </ProfileSegment>
  );
}

export default Dashboard;
