import React, { useContext, useState, useRef, useEffect } from "react";
import Tippy from "@tippy.js/react";
import { createFormContext } from "yafl";
import { Link } from "gatsby";
import updateUserProfile from "../api/updateUserProfile";
import TextInput from "../components/forms/TextInputYafl";
import RadioInput from "./forms/RadioInputYafl";
import DateInput from "./forms/DateInput";
import UserContext from "../context/UserContext";
import Loader from "./Loader";
import Button from "./Button";
import ControlledTooltip from "./tooltips/ControlledTooltip";
import useAuth from "../hooks/useAuth";
import styles from "../styles/BasicInformationForm.module.css";
import ProfileSegment from "./layouts/ProfileSegment";

const { Form, Field } = createFormContext();

function BasicInformationForm() {
  const { data, loading, error } = useContext(UserContext);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isTooltipActive, setTooltipActiveState] = useState(false);
  const { getUserId, getAccessToken } = useAuth();
  const [tooltipMessage, setTooltipMessage] = useState("the boobies");

  function displayTooltip(message: string) {
    setTooltipActiveState(true);
    setTooltipMessage(message);

    timeoutRef.current = global.setTimeout(() => {
      setTooltipActiveState(false);
    }, 3000);
  }

  useEffect(() => {
    return function () {
      if (timeoutRef.current) {
        global.clearTimeout(timeoutRef.current);
      }
    };
  });

  if (loading) {
    return (
      <ProfileSegment>
        <h2 style={{ textAlign: "left" }}>Basic Information</h2>
        <Loader />
      </ProfileSegment>
    );
  }

  if (!data || error) {
    return (
      <ProfileSegment>
        <h2 style={{ textAlign: "left" }}>Basic Information</h2>
        <p>
          We had trouble getting your info. If you keep having trouble,{" "}
          <Link to="/contact-us/">let us know</Link> and we'll work making
          things right.{" "}
        </p>
      </ProfileSegment>
    );
  }

  const initialValue = {
    firstName: data.firstName,
    lastName: data.lastName,
    dateOfBirth: data.dateOfBirth,
    gender: data.gender
  };

  return (
    <ProfileSegment>
      <h2 style={{ textAlign: "left" }}>Basic Information</h2>
      <Tippy content="With data like age, gender, height, and weight, we can improve Cali Skills to make your workouts even more personal.">
        <p
          tabIndex={0}
          style={{
            cursor: "help",
            textDecoration: "underline",
            width: "fit-content"
          }}
        >
          Why do we need this information?
        </p>
      </Tippy>

      <Form
        initialValue={initialValue}
        onSubmit={formValue => {
          displayTooltip("Great, we've updated your details!");
          updateUserProfile(
            getUserId(),
            getAccessToken(),
            formValue as typeof initialValue
          );
        }}
      >
        {yaflProps => {
          const disabled =
            !yaflProps.formIsDirty ||
            Object.keys(yaflProps.touched).length === 0;

          return (
            <form
              className={styles.BasicInformationForm__form}
              data-testid="basic-information-form"
              onSubmit={e => {
                e.preventDefault();

                if (disabled) return;

                yaflProps.forgetState();
                return yaflProps.submit();
              }}
            >
              <div className={styles.BasicInformationForm__section}>
                <Field
                  name="firstName"
                  id="first-name"
                  label="first name:"
                  initialValue="Your name"
                  component={TextInput}
                />
                <Field
                  name="lastName"
                  id="last-name"
                  label="last name:"
                  component={TextInput}
                />
              </div>
              <div className={styles.BasicInformationForm__section}>
                <div className="InputContainer">
                  <label className="InputLabel" htmlFor="email">
                    email:
                  </label>
                  <input
                    className="Input Input--disabled"
                    disabled
                    data-testid="email"
                    id="email"
                    defaultValue={data.email}
                  />
                </div>

                {/* Add verification status to form: https://github.com/andrico1234/calisthenics-skills/issues/216 */}
                {/* <p style={{ maxWidth: "320px" }}>
                  It looks like your email is unverified.{" "}
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Would you like us to send a verification email?
                  </span>
                </p> */}
              </div>
              <div style={{ display: "flex" }}>
                <Field
                  id="dob"
                  disabled
                  name="dateOfBirth"
                  label="date of birth:"
                  component={DateInput}
                />
                <Field
                  name="gender"
                  label="gender:"
                  values={[
                    ["female", "female"],
                    ["male", "male"],
                    ["other", "other"],
                    ["rather not say", "rather not say"]
                  ]}
                  component={RadioInput}
                />
              </div>
              <div style={{ textAlign: "left" }}>
                <ControlledTooltip
                  content={tooltipMessage}
                  isVisible={isTooltipActive}
                >
                  <Button disabled={disabled} type="submit">
                    Save your info
                  </Button>
                </ControlledTooltip>
              </div>
            </form>
          );
        }}
      </Form>
    </ProfileSegment>
  );
}

export default BasicInformationForm;
