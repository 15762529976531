interface Body {
  gender: string;
  dateOfBirth: Date | null;
  firstName: string;
  lastName: string;
}

function updateUserProfile(userId: string, accessToken: string, body: Body) {
  const dateOfBirth = body.dateOfBirth?.toDateString();

  return fetch(`${process.env.GATSBY_SERVER_URL}/users/${userId}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      ...body,
      dateOfBirth
    })
  });
}

export default updateUserProfile;
