import axios from "axios";

interface Args {
  userId: string;
  accessToken: string;
}

async function uncancelSubscription(args: Args) {
  const { userId, accessToken } = args;

  const { data } = await axios.put(
    `${process.env.GATSBY_SERVER_URL}/subscriptions/${userId}/uncancel`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    }
  );

  return data;
}

export default uncancelSubscription;
