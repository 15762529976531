import React from "react";
import useAuth from "../../hooks/useAuth";
import Loader from "../Loader";
import PlanError from "./PlanError";
import FreePlan from "./FreePlan";
import CanceledPlan from "./CanceledPlan";
import PremiumPlan from "./PremiumPlan";
import useGetUserSubscription from "../../hooks/useGetUserSubscription";

function PlanInformation() {
  const { getUserId, getAccessToken } = useAuth();
  const { data, status, error } = useGetUserSubscription();

  if (status === "loading") {
    return <Loader />;
  }

  if (status === "error") {
    console.error(
      error
        ? (error as { message: string }).message
        : "Error fetching plan data"
    );

    return <PlanError />;
  }

  if (!data) {
    return null;
  }

  if (!data.subscription || !data.subscription.active) {
    return <FreePlan />;
  }

  if (data.subscription.state === "canceled") {
    return (
      <CanceledPlan
        accessToken={getAccessToken()}
        userId={getUserId()}
        deactivationDate={data.subscription.deactivationDateDisplay!}
      />
    );
  }

  return (
    <PremiumPlan
      nextChargeDate={data.subscription.nextChargeDateDisplay}
      accessToken={getAccessToken()}
      userId={getUserId()}
    />
  );
}

export default PlanInformation;
