import React from "react";
import { FieldProps } from "yafl";
import RadioInput from "./RadioInput";

function RadioInputYafl(props: FieldProps<{}, string>) {
  const { values, label, meta, input } = props;

  return (
    <RadioInput
      values={values}
      label={label}
      name={input.name}
      selectValue={meta.setValue}
      selectedValue={input.value}
    />
  );
}

export default RadioInputYafl;
