import React from "react";
import ResetSkillsButton from "./ResetSkillsButton";

function AccountActions() {
  return (
    <div className="ProfileSegment">
      <h2 style={{ textAlign: "left" }}>Account Actions</h2>
      <ResetSkillsButton />
    </div>
  );
}

export default AccountActions;
