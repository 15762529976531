import React from "react";
import { FieldProps } from "yafl";
import TextInput from "./TextInput";

function TextInputYafl(props: FieldProps<{}, string>) {
  const { label, id, disabled, input } = props;

  return <TextInput id={id} disabled={disabled} label={label} {...input} />;
}

export default TextInputYafl;
