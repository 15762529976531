import React from "react";
import { Link } from "gatsby";

function FreePlan() {
  return (
    <>
      <p>Plan Type: Free</p>
      <p>Do you want more freedom to shape your bodyweight fitness journey?</p>
      <Link to="/pricing">Have a look at what our premium plan offers</Link>.
    </>
  );
}

export default FreePlan;
