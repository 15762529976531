import React from "react";
import DatePicker from "react-datepicker";
import { FieldProps } from "yafl";
import "react-datepicker/dist/react-datepicker.css";

function DateInput(props: FieldProps<{}, Date | null>) {
  const { label, id, meta, input } = props;

  return (
    <div className="InputContainer InputContainer--small">
      <label className="InputLabel" htmlFor={id}>
        {label}
      </label>
      <DatePicker
        id={id}
        className="Input"
        data-testid={id}
        dateFormat="dd/MM/yyyy"
        placeholderText="dd/mm/yyyy"
        selected={input.value}
        onChange={date => meta.setValue(date)}
      />
    </div>
  );
}

export default DateInput;
