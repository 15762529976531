import React from "react";
import ProfileSegment from "./layouts/ProfileSegment";
import PlanInformation from "./PlanInformation/PlanInformation";

function AccountInformation() {
  return (
    <ProfileSegment>
      <h2 style={{ textAlign: "left" }}>Account Information</h2>
      <PlanInformation />
    </ProfileSegment>
  );
}

export default AccountInformation;
